import { useMsal } from "@azure/msal-react"
import { useState } from "react"
import Menu from "./Menu"
import Board, { BoardProps } from "./Board"
import menuDesktop from "../data/menuDesktop.json"
import menuMobile from "../data/menuMobile.json"
import "./LoggedInn.css"
import Header from "./Header"
import {MenuItemProps} from "./MenuItem";

type LoggedInnProps = {
    tsUserEmail: string
}
const LoggedInn = ({ tsUserEmail }: LoggedInnProps) => {
    const [board, setBoard] = useState<BoardProps>(((window.screen.width > 1100) ? menuDesktop[0] : menuMobile[0]) as BoardProps)
    const [selectedStore, setSelectedStore] = useState<string>("")

    const { accounts } = useMsal()
    const primaryAccount = accounts[0]

    console.debug(`Logged inn with ${accounts.length} account(s)`)

    return (
        <>
            <Header 
                primaryAccount={primaryAccount?.name || primaryAccount?.username || tsUserEmail} boardName={board?.name} 
                user={tsUserEmail} 
                setSelectedStore={setSelectedStore}
                accessLevel={board.accessLevel}
                setBoard={setBoard} />
            <Menu className={"desktop"} setBoard={setBoard} menu={menuDesktop as MenuItemProps[]} />
            <Menu className={"mobile"} setBoard={setBoard} menu={menuMobile as MenuItemProps[]} />
            <main>
                {board &&
                    <Board {...board} selectedStoreNo={selectedStore} />
                }
            </main>
        </>
    )
}

export default LoggedInn
