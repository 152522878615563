// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}

header > * {
    padding: 5px;
}

header > section:first-child {
    cursor: pointer;
}

header > section > * {
    margin: 0;
}

header > section > img {
    height: 70%;
}

header > section > button {
    height: 50%;
    width: 50%;
    font-weight: bold;
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    word-wrap: unset;
    min-width: 90px;
}

header > section > a, header > section > p {
    color: #f8f8f8;
    font-size: 16px;
}

/*  TODO: Sett breakpoint som global variabel*/
@media (max-width: 1100px) {
    header > section > img {
        height: 25px;
    }

    header > section > p {
        font-size: 1em;
    }

    header > h1 {
        font-size: 1.5em;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,UAAU;IACV,iBAAiB;IACjB,6BAA6B;IAC7B,SAAS;IACT,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA,8CAA8C;AAC9C;IACI;QACI,YAAY;IAChB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,gBAAgB;IACpB;;AAEJ","sourcesContent":["header {\n    display: flex;\n    flex-direction: row;\n    align-items: stretch;\n    justify-content: space-between;\n}\n\nheader > * {\n    padding: 5px;\n}\n\nheader > section:first-child {\n    cursor: pointer;\n}\n\nheader > section > * {\n    margin: 0;\n}\n\nheader > section > img {\n    height: 70%;\n}\n\nheader > section > button {\n    height: 50%;\n    width: 50%;\n    font-weight: bold;\n    background-color: transparent;\n    border: 0;\n    border-radius: 50%;\n    cursor: pointer;\n    word-wrap: unset;\n    min-width: 90px;\n}\n\nheader > section > a, header > section > p {\n    color: #f8f8f8;\n    font-size: 16px;\n}\n\n/*  TODO: Sett breakpoint som global variabel*/\n@media (max-width: 1100px) {\n    header > section > img {\n        height: 25px;\n    }\n\n    header > section > p {\n        font-size: 1em;\n    }\n\n    header > h1 {\n        font-size: 1.5em;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
