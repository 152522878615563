import "./BoardDefault.css"

const BoardDefault = () => {
    return (
        <>
            <embed src={"/images/landingssiden.pdf#toolbar=0&navpanes=0&scrollbar=0"} />
        </>
    )
}

export default BoardDefault
