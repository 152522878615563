import { useMsal } from "@azure/msal-react"
import LogoutIcon from '@mui/icons-material/Logout'
import "./Header.css"
import { logout } from "@thoughtspot/visual-embed-sdk"
import ChooseStore from "./ChooseStore"
import { BoardProps } from "./Board"
import menuDesktop from "../data/menuDesktop.json"
import menuMobile from "../data/menuMobile.json"

type HeaderProps = {
    primaryAccount: string,
    boardName?: string,
    user: string,
    accessLevel: string
    setSelectedStore: (arg0: string) => void,
    setBoard: (arg0: BoardProps) => void
}

const Header = ({ primaryAccount, boardName, user, setSelectedStore, setBoard, accessLevel }: HeaderProps) => {

    const { instance } = useMsal()

    const logOut = () => {
        console.log("Logging out")
        logout()
        instance.logoutRedirect()
    }

    return (
        <header>
            <section onClick={() => setBoard(((window.screen.width > 1100) ? menuDesktop[0] : menuMobile[0]) as BoardProps)}>
                <img alt="" src="./logo-hvit.webp"/>
                {/* Only showing first name on mobile, showing "Hei, <full name> on desktop> */}
                <p><span className={"desktop"}>Hei, </span>{primaryAccount.split(" ")[0]}<span className={"desktop"}> {primaryAccount.split(" ").slice(1).join(" ")}</span></p>
            </section>
            {(process.env.REACT_APP_ENVIRONMENT !== "prod") &&
                <section className={"desktop"}>
                    <h2>
                        {process.env.REACT_APP_ENVIRONMENT}
                    </h2>
                </section>}
            <section>
                <h1>{boardName || "Rapporter"}</h1>
                <ChooseStore {...{setSelectedStore, user, accessLevel}} />
            </section>
            <section className={"desktop"}>
                <h1>Tilbakemeldinger?</h1>
                <a href="mailto:rapportrommet@europris.no">rapportrommet@europris.no</a>
            </section>
            <section>
                <button onClick={() => logOut()}><LogoutIcon/><br />Logg ut</button>
            </section>
        </header>
    )
}

export default Header
