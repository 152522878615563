type BoardPdfProps = {
    fileName: string
}


const BoardPdf = ({ fileName }: BoardPdfProps) => {
    return (
        <embed src={`/images/${fileName}#toolbar=0&navpanes=0&scrollbar=0`} />
    )
}

export default BoardPdf
