import './App.css';
import LogInTs from './components/LogInnTs';
import { Configuration, InteractionType, PublicClientApplication } from '@azure/msal-browser'
import { MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react'


const msalConfig: Configuration = {
	auth: {
		//clientId: "8acc5ee6-a7e6-4f5e-84da-6edaf753c111",  // Fra Enterprise Appliation -> dwh-frontend-reports -> Application ID
		clientId: "60414fb8-3c9e-4c2a-b229-6db1bde2402b",  // Fra WebApp -> dwh-reports-frontend -> Authentication
		authority: "https://login.microsoftonline.com/9e30ff59-e953-4b82-89b2-5ccabab1efdb"
	}
}
const pca = new PublicClientApplication(msalConfig)


function App() {
	return (
		<div className="App">
			<MsalProvider instance={pca}>
				<MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >

					<LogInTs />
				</MsalAuthenticationTemplate>
				<UnauthenticatedTemplate>
					{
						process.env.REACT_APP_ENVIRONMENT === "local"
							?
						<p>Kunne ikke logge inn, lokalt</p>
							:
						<p>Logger inn...</p>
					}
				</UnauthenticatedTemplate>
			</MsalProvider>
		</div>
	)
}

export default App;
