// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select {
    display: block;
    text-align: center;
    padding: 0.5em;
    background-color: transparent;
    font-weight: 900;
    border: 0 solid transparent;
    max-width: 3000px;
    overflow-wrap: normal;
}

@media (max-width: 1100px) {
    select {
        max-width: 150px;
    }
}

select >:disabled {
    display: none;
}

select > option {
    display: inline-block;
    overflow-wrap: break-word;
    word-break: break-word;
    background-color: #272525;
    font-weight: 900;
}`, "",{"version":3,"sources":["webpack://./src/components/ChooseStore.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,cAAc;IACd,6BAA6B;IAC7B,gBAAgB;IAChB,2BAA2B;IAC3B,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,sBAAsB;IACtB,yBAAyB;IACzB,gBAAgB;AACpB","sourcesContent":["select {\n    display: block;\n    text-align: center;\n    padding: 0.5em;\n    background-color: transparent;\n    font-weight: 900;\n    border: 0 solid transparent;\n    max-width: 3000px;\n    overflow-wrap: normal;\n}\n\n@media (max-width: 1100px) {\n    select {\n        max-width: 150px;\n    }\n}\n\nselect >:disabled {\n    display: none;\n}\n\nselect > option {\n    display: inline-block;\n    overflow-wrap: break-word;\n    word-break: break-word;\n    background-color: #272525;\n    font-weight: 900;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
