// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

/*  Hides "desktop" class when screen is small  */
@media (max-width: 1100px) {
  .desktop {
    display: none;
  }
}

/*  Hides "mobile" class when screen is big  */
/*  1 pixel bigger than mobile, to make sure media rules dont overlap (and hides both)  */
@media (min-width: 1101px) {
  .mobile {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA,iDAAiD;AACjD;EACE;IACE,aAAa;EACf;AACF;;AAEA,8CAA8C;AAC9C,yFAAyF;AACzF;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["* {\n  box-sizing: border-box;\n}\n\n.App {\n  text-align: center;\n  height: 100vh;\n  max-height: 100vh;\n  display: flex;\n  flex-direction: column;\n}\n\nmain {\n  flex-grow: 1;\n}\n\n/*  Hides \"desktop\" class when screen is small  */\n@media (max-width: 1100px) {\n  .desktop {\n    display: none;\n  }\n}\n\n/*  Hides \"mobile\" class when screen is big  */\n/*  1 pixel bigger than mobile, to make sure media rules dont overlap (and hides both)  */\n@media (min-width: 1101px) {\n  .mobile {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
