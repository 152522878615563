import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material"
import { useState } from "react"
import { BoardProps, BoardTypes } from "./Board";
import "./MenuItem.css"


interface ChildItem {
    setBoard: (_: BoardProps) => void,
    level: string,
    name: string,
    boardId: string,
    accessLevel: string,
    tabs: string[],
    visualizations: string[],
    type: BoardTypes,
    toggleExpanded?: () => void,
    sendFilter: boolean,
    sendParameter: boolean,
    hideHeader: boolean
}
interface ParentItem {
    setBoard: (_: BoardProps) => void,
    level: string,
    name: string,
    childItems: ChildItem[]
}
export type MenuItemProps = ParentItem | ChildItem
const MenuItem = (props: MenuItemProps) => {
    // Character(s) to use when indenting different levels
    const intentation = " "

    const [expanded, setExpanded] = useState(false)
    const toggleExpanded = () => {
        setExpanded(!expanded)
    }

    if ("boardId" in props) {
        const handleClick = async () => {
            if (window.innerWidth <= 1100) {
                console.debug("Small screen, closing menu")
                if (props.toggleExpanded) {
                    props.toggleExpanded()
                }
            } else {
                console.debug("Big screen, not closing menu")
            }

            console.log("Setter riktig board")
            props.setBoard({
                name: props.name,
                boardId: props.boardId,
                type: props.type,
                accessLevel: props.accessLevel,
                sendFilter: props.sendFilter,
                sendParameter : props.sendParameter,
                hideHeader : props.hideHeader
            })
        }

        return (
            <List sx={{'& .MuiListItemButton-root:hover': {bgcolor: "#373535"}}}>
                <ListItemButton key={props.name} onClick={() => handleClick()} >
                    <pre>{props.level}</pre>
                    <ListItemText primary={props.name} />
                </ListItemButton>
            </List>
        )
    }

    return (
        <List sx={{'& .MuiListItemButton-root:hover': {bgcolor: "#373535"}}}>
            <ListItemButton key={props.name} onClick={() => toggleExpanded()}>
                <pre>{props.level}</pre>
                <ListItemText primary={`${props.level}${props.name}`} />
                {expanded ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {props.childItems.map((item) => (
                        <MenuItem key={item.name} {...item} 
                            level={props.level + intentation}
                            setBoard={props.setBoard}
                            toggleExpanded={toggleExpanded} />
                    ))}                        
                </List>
            </Collapse>
        </List>
    )
}

export default MenuItem
