import { SyntheticEvent, useEffect, useState } from "react"
import "./ChooseStore.css"
import Modal from "./Modal"

type ChooseStoreProps = {
    user: string,
    setSelectedStore: (arg0: string) => void,
    accessLevel: string
}

const ChooseStore = ({ user, setSelectedStore, accessLevel }: ChooseStoreProps) => {
    const [avalibaleStores, setAvalibaleStores] = useState<{allStores: {storeNo: string, storeNoName: string}[], defaultStore: string, finance: string[], hr: string[], sales: string[]}>()
    const [defaultStore, setDefaultStore] = useState<string>("")
    const [modal, setModal] = useState<{open: boolean, header?: string, text?: string}>({open: false})

    const selectStore = (event: SyntheticEvent<HTMLSelectElement>) => {
        event.preventDefault()
        
        const storeNo = event.currentTarget.value
        
        switch (accessLevel) {
            case "finance":
                console.debug("finance")
                if (avalibaleStores?.finance.includes(storeNo)) {
                    setSelectedStore(storeNo)
                } else {
                    console.warn("Trying to access a report with insufficent access")
                    setSelectedStore(defaultStore)
                    setModal({
                        open: true,
                        header: "Manglende tilgang",
                        text: "Denne rapporten inneholde finansiell informasjon, som du ikke har tilgang til for denne butikken. Du ser nå på din standarbutikk istedenfor"
                    })
                }
                break
            case "hr":
                console.debug("hr")
                if (avalibaleStores?.hr.includes(storeNo)) {
                    setSelectedStore(storeNo)
                } else {
                    console.warn("Trying to access a report with insufficent access")
                    setSelectedStore(defaultStore)
                    setModal({
                        open: true,
                        header: "Manglende tilgang",
                        text: "Denne rapporten inneholder sensetive personopplysninger, som du ikke har tilgang til å se for denne butikken. Du ser nå på din standarbutikk istedenfor"
                    })
                }
                break
            case "sales":
                console.debug("sales")
                if (avalibaleStores?.sales.includes(storeNo)) {
                    setSelectedStore(storeNo)
                } else {
                    console.warn("Trying to access a report with insufficent access")
                    setSelectedStore(defaultStore)
                    setModal({
                        open: true, 
                        header: "Manglende tilgang", 
                        text: "Denne rapporten inneholder salgstall, som du ikke har tilgang til for denne butikken. Du ser nå på din standarbutikk istedenfor"
                    })
                }
                break
            default:
                console.error`Access type is not supported in 'ChooseStore', got ${accessLevel}`
                setSelectedStore(defaultStore)
        }


    }

    useEffect(() => {
        const fetchAvalibaleStores = async () => {
            if (!process.env.REACT_APP_API_AVALIBALE_STORES_URL) {
                alert("REACT_APP_API_AVALIBALE_STORES_URL mangler")
                return
            }
            if (!process.env.REACT_APP_API_AVALIBALE_STORES_TOKEN) {
                alert("REACT_APP_API_AVALIBALE_STORES_TOKEN mangler")
                return
            }
            if (!process.env.REACT_APP_API_AVALIBALE_STORES_HEADER) {
                alert("REACT_APP_API_AVALIBALE_STORES_HEADER mangler")
                return
            }

            const headers = new Headers()
            headers.append("Content-Type", "application/json")
            headers.append(process.env.REACT_APP_API_AVALIBALE_STORES_HEADER, process.env.REACT_APP_API_AVALIBALE_STORES_TOKEN)
            
            // Logic app i LDTP -> databricks-reports-storeAccess
            const response = await fetch(process.env.REACT_APP_API_AVALIBALE_STORES_URL, {
                method: "POST",
                body: JSON.stringify({
                    "user": user
                }),
                headers: headers
            })
            
            const body = await response.json()
            
            if (body.success) {
                console.debug(body.data)
                setDefaultStore(body.data.defaultStore)
                setSelectedStore(body.data.defaultStore)
                setAvalibaleStores(body.data)
            } else {
                setModal({
                    open: true,
                    header: "Noe er feil i LDTP (Lille Digitale TjenestePlattform)",
                    text: body.error
                })
            }
        }
        fetchAvalibaleStores()

    }, [user, setSelectedStore])

    return (
        <>
            {modal.open && 
                <Modal close={() => setModal({open: false})} >
                    <h2>{modal.header || "Noe er feil :/"}</h2>
                    <p>{modal.text || "Kontakt IT support hvis dette skjer gjentatte ganger"}</p>
                </Modal>
            }
            <select defaultValue={"DEFAULT"} onChange={e => selectStore(e)} >
                <option value={defaultStore} selected disabled>
                    {defaultStore 
                        &&
                    `Butikk ${defaultStore} - Velg en annen butikk`}
                </option>
                {avalibaleStores?.allStores.map((store) => (
                    <option value={store.storeNo} key={store.storeNo}>{store.storeNoName}</option>
                ))}
            </select>
        </>
    )
}

export default ChooseStore
