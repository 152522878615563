import { useEffect, useState } from "react"
import "./LoggedInn.css"
import { AuthEvent, AuthStatus, AuthType, init, getSessionInfo } from "@thoughtspot/visual-embed-sdk"
import LoggedInn from "./LoggedInn"

const LogInTs = () => {

    const [tsUserEmail, setTsUserEmail] = useState()

    /*   Using different init arguments based on screen width. CSS properties needs to be set when initialized, and
     *   for making these properties easier to read/change it is simpler to use 2 instances
     */
    const tsLogin = (window.screen.width > 1100)
        ?
    init({
        thoughtSpotHost: "https://tribunen.europris.no",
        loginFailedMessage: "Kunne ikke logge inn mot ThoughtSpot",
        authType: AuthType.SAMLRedirect,
        inPopup: true
    })
    :
    init({
        thoughtSpotHost: "https://tribunen.europris.no",
        loginFailedMessage: "Kunne ikke logge inn mot ThoughtSpot",
        authType: AuthType.SAMLRedirect,
        inPopup: true,
        customizations: {
                    style: {
                        customCSS: {
                            // ThoughtSpot has some CSS variables that can be used to overwrite other thir default values. However, they do not
                            // manipulate the things we need to get it working on a phone
                            rules_UNSTABLE: {
                                // Common elements
                                // Outermost element of the iFrame/liveboard
                                ".pinboard-content-module__fixedLayoutTileContainer": {
                                    "width": `${window.screen.width - 10}px!important`,
                                    "height": "fit-content!important",
                                    "padding-top": "20px!important",
                                    "padding": "20px auto!important",
                                    "display": "flex!important",
                                    "flex-direction": "column",
                                    "align-items": "center",
                                    "justify-content": "center",
                                    "column-gap": "auto!important",
                                    "row-gap": "20px"
                                },
                                // Outermost element of each card
                                ".react-resizable": {
                                    // Padding for the cards on the liveboard is 24px, times 2 => 48 pixels
                                     "width": `${(window.screen.width - 48)}px!important`,
                                },
                                // Wrapper for title of card. Close to to level, but not quite
                                ".answer-content-header-module__container": {
                                    "padding": "0px!important"
                                },
                                // Placeholder for description between title and number. As we are not using description, this is removed.
                                // TODO: Sjekk med Hege/Zaki/Thomas om vi noen gang kommer til å bruke den. Helst ikke
                                ".answer-title-module__descriptionTextOneLine": {
                                    "display": "none!important"
                                },

                                // KPI cards
                                // Makes sure numbers are displayed
                                ".legacy-chart-component": {
                                    "min-height": "fit-content!important",
                                    "height": "fit-content!important"
                                },
                                ".chartSection": {
                                    "min-height": "fit-content!important",
                                    "height": "30px!important"
                                },
                                ".vizWrapper": {
                                    "height": "fit-content!important"
                                },
                                ".chart-area": {
                                    "height": "fit-content!important"
                                },
                                ".kpi-module__kpi": {
                                    "height": "fit-content!important"
                                },
                                
                                // Table cards. CSS classes often starting with ag-, as in aggregated
                                // Makes table only take as much space as needed
                                // Outermost
                                ".pinboard-content-module__tile": {
                                    "min-height": "84px!important",
                                    "height": "fit-content!important",
                                    "position": "unset!important",
                                    "transform": "none!important"
                                },
                                ".flex-layout-module__horizontal": {
                                    "height": "fit-content!important",
                                    "min-height": "fit-content!important",
                                    "flex": "1 1 initial!important"
                                },
                                ".answer-content-module__answerContent": {
                                    "height": "fit-content!important",
                                    "min-height": "fit-content!important"
                                },
                                ".answer-content-module__tableVizSection": {
                                    "height": "fit-content!important",
                                    "min-height": "fit-content!important"
                                },
                                ".table-viz-module__container": {
                                    "height": "fit-content!important",
                                    "min-height": "fit-content!important"
                                },
                                ".table-module__fullContainer": {
                                    "height": "fit-content!important",
                                    "min-height": "fit-content!important"
                                },
                                ".ag-layout-normal": {
                                    "height": "fit-content!important",
                                    "min-height": "fit-content!important"
                                },
                                ".ag-center-cols-clipper": {
                                    "height": "fit-content!important",
                                    "min-height": "fit-content!important"
                                },
                                ".ag-center-cols-viewport": {
                                    "height": "fit-content!important"
                                },
                                // Innermost
                            }
                        }
                    }
                }
    })

    tsLogin.on(AuthStatus.WAITING_FOR_POPUP, () => {
        console.log("Trigger SSO pop-up")
        tsLogin.emit(AuthEvent.TRIGGER_SSO_POPUP)

    })
    tsLogin.on(AuthStatus.FAILURE, (failureType) => {
        console.warn(`Authentication aginst ThoughSpot failed, returned AuthFailureType: ${failureType}`)
        alert("Kunne ikke autentisere bruker!")
    })

    let hasPrintedTsUser = false
    const handleTsUser = async () => {
        const user = await getSessionInfo()
        setTsUserEmail(user.userName)
        if (!hasPrintedTsUser) {
            console.log(user)
            hasPrintedTsUser = true
        }
    }

    useEffect(() => {
        handleTsUser()
    })

    return (
        <>
            {
                tsUserEmail
                    ?
                <LoggedInn tsUserEmail={tsUserEmail} />
                    :
                <p>Logger inn...</p>
            }
        </>
    )
}

export default LogInTs
