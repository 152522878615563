import "./Modal.css"

type ModalProps = {
    children: JSX.Element | JSX.Element[],
    close: () => void,
}

const Modal = ({ children, close }: ModalProps) => {

    return (
        <dialog onClick={() => close()}>
            <section>
                {children}
            </section>
        </dialog>
    )
}

export default Modal