import Divider from '@mui/material/Divider'
import MenuItem, { MenuItemProps } from './MenuItem'
import { BoardProps } from './Board'
import { Fragment } from 'react'

export type MenuProps = {
    className: string,
    setBoard: (_: BoardProps) => void,
    menu: MenuItemProps[]
}

const Menu = ({ className, setBoard, menu }: MenuProps) => {

    // Shows a loading gif when menu is loaded, for when menu is loaded dynamically
    if (!menu) {
        return (<img src="/loading.gif" width={50} height={50} alt='Laster data' />)
    }

    return (
        <nav className={className}>
            {menu.map(item => (
                <Fragment key={item.name}>
                    <MenuItem key={item.name} {...item} level='' setBoard={setBoard} />
                    <Divider key={item.name + "divider"} />
                </Fragment>
            ))}
        </nav>
    )
}

export default Menu
